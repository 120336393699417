import React from "react";
import theme from "theme";
import { Theme, Text, LinkBox, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Business Administration in Cyprus | Taskmasters
			</title>
			<meta name={"description"} content={"A business administration and accounting company. We are located in Cyprus Limassol"} />
			<meta property={"og:title"} content={"Business Administration in Cyprus | Taskmasters"} />
			<meta property={"og:description"} content={"A business administration and accounting company. We are located in Cyprus Limassol"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/ogimage.png?v=2024-02-14T13:32:07.319Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:47.550Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:24:57.135Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/657c2c8a8d4a0c0020281f8d/images/favicon.png?v=2024-02-14T13:25:03.164Z"} />
		</Helmet>
		<Section
			padding="150px 0 150px 0"
			min-height="100vh"
			background="linear-gradient(180deg,--color-light 0%,transparent 86.7%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1600935223913-c074ba22a8f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0% /cover no-repeat scroll padding-box"
			lg-padding="90px 0 90px 0"
			quarkly-title="404-2"
		>
			<Override slot="SectionContent" max-width="1220px" justify-content="flex-start" />
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--dark">
					404
				</Text>
				<Text
					color="--dark"
					margin="8px 0px 16px 0px"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					font="--headline1"
					letter-spacing="-0.025em"
					lg-margin="0px 0px 16px 0px"
				>
					Page not found
				</Text>
				<Text
					lg-width="80%"
					font="--lead"
					color="--dark"
					margin="0px 0px 36px 0px"
					text-align="center"
					lg-margin="0px 0px 24px 0px"
				>
					Sorry, we couldn’t find the page you’re looking for.
				</Text>
				<LinkBox
					flex-direction="row"
					padding="10px 24px 12px 24px"
					transition="--opacityOut"
					hover-opacity="0.7"
					lg-margin="0px 0px 20px 0px"
					background="--color-primary"
					border-radius="22px"
				>
					<Text margin="0px 0px 0px 0px" font="--lead" color="white">
						Back to home
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"657c2c8a8d4a0c0020281f8b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});